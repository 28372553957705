import styled from 'styled-components'
import { graphql, Link } from "gatsby"
import React, { Component } from "react"
import "@babel/polyfill";

import Layout from "../layouts"
import Head from "../components/head"
import DefaultHero from '../components/default-hero';

import '../css/client-list.css';

import YoastSeoParts from '../fragments/seo';

class ClientLoginTemplate extends Component {

  render() {
    const page = this.props.data.wpPage;
    const { seo } = page
    const { clientLogins, centerCopy, pageAccentColor } = page.template.templateClientLogin;

    return (
      <Layout>
        <PageWrapper accentColor={pageAccentColor || '#21c6be'}>
          <Head title={seo.title} description={seo.metaDesc} meta={seo} />

          <DefaultHero title={page.title} backgroundColor="#65869C" />

          <div className="container client-login-wrapper" style={{ paddingTop: '20px' }}>
            <div className="fullWidhtContainer">
              <div className="grid smallGrid">
                <div className="col-sm-12 maincopy" dangerouslySetInnerHTML={{ __html: centerCopy }} />
              </div>
            </div>

            <div className="ContainerRepeatLogin">
              {clientLogins && clientLogins.map(({ title, copy, cta, image }) => (
                <div key={title} className="grid smallGrid align-center gridRepeat">
                  <div className="col-sm-4 repeatLeft">
                    {image && <img src={image.localFile.publicURL} alt="" className="flex-img" />}
                  </div>
                  <div className="col-sm-8 maincopy repeatRight">
                    <h3>{title}</h3>
                    <div dangerouslySetInnerHTML={{ __html: copy }} />
                    { cta &&
                      <Link 
                        className="mainCTA" 
                        to={cta.url}
                        target={cta.target || '_self' }
                      >{cta.title ?? 'Link'}</Link>
                    }
                  </div>
                </div>
              ))}
            </div>
          </div>
        </PageWrapper>
      </Layout>
    )
  }
}

const PageWrapper = styled.div`
  .defaultHero .dots span {
    color: ${({ accentColor }) => accentColor};
  }

  .defaultHero {
    border-top-color: ${({ accentColor }) => accentColor};
  }
`

export default ClientLoginTemplate

export const query = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      uri
      id
      databaseId
      seo {
        ...seoParts
      }
      template {
        ... on WpTemplate_ClientLogin {
          templateName
          templateClientLogin {
            pageAccentColor
            centerCopy
            clientLogins {
              copy
              fieldGroupName
              title
              cta {
                target
                title
                url
              }
              image {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  }
`